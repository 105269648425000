import React, { useState } from 'react'
import Layout from '../components/layout/Layout'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

let helmut = {
  title: 'About Treeland Trails',
  description: [
    'Treeland Trails is located in Fort Jones, CA.  Contact us at info@treelandtrails.com',
  ].join('\n'),
  keywords: [
    'regenerative agriculture, ranching, forestry, keyline, mountain biking',
  ].join('\n'),
}

const About = () => {
  return (
    <Layout
      helmutTitle={helmut.title}
      helmutDescription={helmut.description}
      helmutKeywords={helmut.keywords}
    >
      <div id="about" className="page-content">
        <section id="intro">
          <div className="inner">
            <header className="">
              <h1>About</h1>
            </header>
            <p>
              Treeland Trails is nearly eight square miles of land in Siskiyou
              County, northern California. It is vibrant and diverse, fully
              encompasing the Cottonwood River watershed with elevations from
              3400’ to 6000’. It has oak, pine, and mixed conifer forests, plus
              meadows and brushland with stunning views.
            </p>
          </div>

          <div className="about-images-container">
            <div className="item">
              <StaticImage
                src="../assets/images/about/manzanitaMountainView600x450.jpg"
                alt="Manzanita Mountain View"
                layout="constrained"
                placeholder="blurred"
                width={400}
              />
            </div>
            <div className="item">
              <StaticImage
                src="../assets/images/about/oakInPerlCanyon600x450.jpg"
                alt="Oak Tree In Perl Canyon"
                layout="constrained"
                placeholder="blurred"
                width={400}
              />
            </div>
            <div className="item">
              <StaticImage
                src="../assets/images/about/oakBrush600x450.jpg"
                alt="Oak And Brush"
                layout="constrained"
                placeholder="blurred"
                width={400}
              />
            </div>
            <div className="item fourth">
              <StaticImage
                src="../assets/images/about/trees600x450.jpg"
                alt="Trees"
                layout="constrained"
                placeholder="blurred"
                width={400}
              />
            </div>
          </div>
          <div id="photo-album-button">
            <Link to="/photos/hero" className="button">
              See More Photos
            </Link>
          </div>

          <div id="portrait">
            <div className="inner">
              <p>
                We are a group of comitted individuals, passionate about
                restoring the land to create working, functioning ecosystems and
                foster community along the full spectrum of living species
              </p>
              <div className="container">
                <div className="item">
                  <StaticImage
                    src="../assets/images/about/ChrisProfileNov22-600x705.jpg"
                    alt="Chris Land"
                    layout="constrained"
                    placeholder="blurred"
                    width={400}
                  />
                  <span className="caption">Chris Land</span>
                </div>
                <div className="item">
                  <StaticImage
                    src="../assets/images/about/BarbTreesNov21-600x706.jpg"
                    alt="Barbara Raitz"
                    layout="constrained"
                    placeholder="blurred"
                    width={400}
                  />
                  <span className="caption">Barbara Raitz</span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="location">
          <div className="inner">
            <header className="">
              <h1>Location</h1>
            </header>
            <p>We're located near Fort Jones, northern California.</p>

            <div className="directions-container">
              <div className="item map">
                <StaticImage
                  src="../assets/images/about/TreelandTrailsMapLocation_600x676.jpg"
                  alt="Map of California"
                  layout="constrained"
                  placeholder="blurred"
                />
                <div className="spacer" />
                <StaticImage
                  src="../assets/images/about/TreelandTrailsLocationWeedYrekaFtJones_600x516.jpg"
                  alt="Map of California"
                  layout="constrained"
                  placeholder="blurred"
                />
                <div className="spacer" />
                <StaticImage
                  src="../assets/images/about/TreelandTrailsBoundaryFromGoogleEarth_600x299.jpg"
                  alt="property boundaries"
                  layout="constrained"
                  placeholder="blurred"
                />
              </div>

              <div className="item polebarn">
                <div className="">
                  <Link to="https://goo.gl/maps/LHBzWdGFb8XJDoFTA">
                    <StaticImage
                      src="../assets/images/about/20200708_poleBarn.jpg"
                      alt="Cedar Gulch Pole Barn"
                      layout="constrained"
                      placeholder="blurred"
                    />
                  </Link>
                  <p>
                    We frequently meet{' '}
                    <Link to="https://www.google.com/maps/place/41%C2%B034'35.6%22N+122%C2%B041'46.4%22W/@41.576544,-122.6984187,17z/data=!3m1!4b1!4m6!3m5!1s0x0:0x6f35b0205cb340d1!7e2!8m2!3d41.5765444!4d-122.6962298">
                      HERE
                    </Link>{' '}
                    at the Cedar Gulch pole barn. Keep in mind, there is no cell
                    phone service -- this is your chance to go off grid!
                  </p>
                </div>
                <h3>Directions</h3>
                <ul>
                  <li>
                    From Yreka Walmart,{' '}
                    <b>take CA Hwy 3 South 8.7 miles towards Fort Jones</b>. Go
                    up and down Forest Mountain Pass
                  </li>
                  <li>
                    <b>Turn LEFT onto Moffett Creek Road</b> (just before you
                    reach the valley floor) -- also called Peach Orchard Rd
                  </li>
                  <li>
                    <b>Drive 7.5 miles</b> to pole barn
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <div id="four">
          <div className="inner">
            <header className="">
              <h1>Contact Us</h1>
            </header>
            <p>
              Let's talk! Send an email to:{' '}
              <a href="mailto: hello@treelandtrails.com">
                hello@treelandtrails.com
              </a>
              !
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

About.propTypes = {}

export default About
